import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));
// Dashboard
const Home = React.lazy(() => import("./components/pages/Home"));

const Reports = React.lazy(() => import("./components/pages/Reports"));

const Receipts = React.lazy(() => import("./components/pages/Receipts"));

const Config = React.lazy(() => import("./components/pages/Config"));

const Defaultlogin = React.lazy(() => import("./components/pages/prebuilt-pages/Defaultlogin"));

function App() {
  return (
    <Router basename={"/"}>
      <Suspense fallback={<div></div>}>
        <Preloader />
        <Switch>
          {/* Dashboard */}
          <Route exact path="/" component={Home} />

          <Route exact path="/reports" component={Reports} />

          <Route exact path="/receipts" component={Receipts} />

          <Route exact path="/setup" component={Config} />

          <Route exact path="/default-login" component={Defaultlogin} />

          <Route path="/" component={Home} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
